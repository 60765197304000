import { EditOutlined } from '@mui/icons-material';
import { Textarea } from '@mui/joy';
import { Alert, Box, FormControl, InputLabel, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function EditFieldDialog(data) {
    const [open, setOpen] = React.useState(false);
    const [field, setField] = React.useState();
    const { t/*, i18n*/ } = useTranslation();

    //tendina
    const [selectedVal, setSelectedVal] = React.useState();
    const [suggestedVal, setSuggestedVal] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        data.closeMenu();
        setOpen(false);
    };

    React.useEffect(() => {
        if (Array.isArray(data.row.modified)) {
            let selectedValues = data.row.modified.filter((v) => v.selected === true);
            if (selectedValues && selectedValues.length > 0)
                setSelectedVal(selectedValues[0].descr)
        }

        setField(data.row);
    },
        [data.row])

    return (
        <React.Fragment>

            {!open &&
                <Button className='button-style' startIcon={<EditOutlined />} variant="text" onClick={handleClickOpen}>
                    Modifica
                </Button>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();

                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const modifiedValue = formJson.modified;

                        // se faccio update di un valore primitivo
                        if (!selectedVal) {
                            data.onUpdate([{ "row": field, "value": modifiedValue }]);
                        }
                        // diversamente ripasso l'array
                        else {
                            let modifiedField = [...field.modified];
                            // //tolgo primo elemento descrizione
                            // modifiedField.shift();

                            for (let f of modifiedField) {
                                if (f.descr === selectedVal) {
                                    f.selected = true;

                                    if (f.codice_mdf && f.codice_mdf.field_name) {
                                        let rowToUpdate = {};
                                        let index = data.cimFields.findIndex(d => d.key === f.codice_mdf.field_name);

                                        if (index > -1) {
                                            rowToUpdate = data.cimFields[index];
                                            data.onUpdate([{ "row": field, "value": modifiedField }, { "row": rowToUpdate, "value": f.codice_mdf.value ? f.codice_mdf.value : "" }]);
                                        }
                                    }
                                    else
                                        data.onUpdate([{ "row": field, "value": modifiedField }]);
                                }
                                else
                                    f.selected = false;
                            }
                        }

                        handleClose();
                    },
                }}
            >
                <DialogTitle>Modifica Attributo {data.label} </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Seleziona di seguito il valore corretto
                    </DialogContentText>
                    {Array.isArray(data.row.modified) ?
                        <>
                            {
                                data.row.modified.length > 0 &&
                                    <Box sx={{ mt: 2, fontStyle: "italic" }}>*Valore reperito da {t("cim." + (data.row.modified[0].field_name ? data.row.modified[0].field_name : (data.row.modified[0]["codice_mdf"].field_name ? data.row.modified[0]["codice_mdf"].field_name : "no name")))} ({data.row.modified[0].search_text})</Box>
                            }
                            <FormControl sx={{ mt: 4 }} fullWidth>
                                <InputLabel id="tv">Seleziona Un Valore</InputLabel>
                                <Select
                                    value={selectedVal ? selectedVal : ""}
                                    label="Seleziona un Valore"
                                    id="tv"
                                    onChange={(e) => setSelectedVal(e.target.value)}
                                    name="modified"
                                    autoFocus
                                    required
                                >
                                    {
                                        data.row.modified.map((v, i) => {
                                            if (i > 0) return <MenuItem key={v.codice} value={v.descr}>{v.descr}</MenuItem>
                                            else return null
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </>
                        :
                        <>
                            <FormControl sx={{ mt: 3 }} fullWidth>
                                <InputLabel id="sv">Valori Suggeriti</InputLabel>

                                <Select
                                    sx={{ display: data.row.extracted || data.row.orfeus || data.row.normalized }}
                                    label="Valori Suggeriti"
                                    value=""
                                    name="suggestedValues"
                                >

                                    {data.row.extracted &&
                                        <MenuItem onClick={(e) => setSuggestedVal(data.row.extracted)} value={data.row.extracted}>{"Estrazione : " + data.row.extracted}</MenuItem>
                                    }
                                    {data.row.orfeus &&
                                        <MenuItem onClick={(e) => setSuggestedVal((data.row.orfeus instanceof Object) ? data.row.orfeus[0].text : data.row.orfeus)} value={(data.row.orfeus instanceof Object) ? data.row.orfeus[0].text : data.row.orfeus}>
                                            {(data.row.orfeus instanceof Object) ? "Orfeus : " + data.row.orfeus[0].text : "Orfeus : " + data.row.orfeus}
                                        </MenuItem>
                                    }
                                    {data.row.normalized &&
                                        <MenuItem onClick={(e) => setSuggestedVal(data.row.normalized)} value={data.row.normalized}>{"Pulizia : " + data.row.normalized}</MenuItem>
                                    }
                                </Select>
                            </FormControl>

                            {(data.row.key === '29_drafting_date' || data.row.key === '16_date_acc_point') && <Alert severity='info' sx={{ mt: 2 }}>Inserire il valore nel formato YYYY-MM-dd hh:mm:ss</Alert>}

                            <Textarea
                                sx={{ mt: 3 }}
                                autoFocus
                                // required
                                minRows={5}
                                size="md"
                                name="modified"
                                onChange={(e) => setSuggestedVal(e.target.value)}
                                value={suggestedVal !== "" ? suggestedVal : data.row.modified}
                            />
                        </>}
                </DialogContent>
                <DialogActions>
                    <label style={{color:"red"}}>{data.row!=null && (data.row.key=="4_customer_recipient_name" || data.row.key=="1_customer_sender_name")?"Ricordarsi di ricalcolare la codifica internazionale dopo la modifica":""}</label>
                    <Button color='inherit' onClick={handleClose} variant='outlined'>Annulla</Button>
                    <Button variant='contained' type="submit">Modifica</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}