import { Close, OpenInNew, Search } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { searchRID } from '../../client/apiClient';
import { selectedMailAtom, warningMsg } from '../../states/wizardState';

const RIDDialog = (props) => {

    const [rid, setRid] = useState();
    const [modifiedRid, setModifiedRid] = useState();

    const [selectValues, setSelectValues] = useState({});

    const [open, setOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const [searchRidOpen, setSearchRidOpen] = useState(false);
    const [searchRidResults, setSearchRidResults] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleTabChange = (event, newValue) => setTabValue(newValue);

    const selectedMail = useRecoilValue(selectedMailAtom);

    const setWarningMessage = useSetRecoilState(warningMsg);

    const updateRid = (validate, discard) => {
        props.updateRID(modifiedRid, rid.IdRID, validate, discard);
    }

    const handleChange = (key, val) => {
        let mr = { ...modifiedRid };
        mr[key] = val;
        setModifiedRid(mr);
    }

    const searchRIDByOnu = (codiceOnu) => {
        let input = {};
        input.id_mail = selectedMail.id_mail;
        input.id_rid = rid.IdRID;
        input.codice_onu = codiceOnu;

        searchRID(input).then(res => {
            if (res.data.result.length === 0) {
                setWarningMessage("Nessuna corrispondenza individuata");
            }
            else {
                setSearchRidOpen(true);
                setSearchRidResults(res.data.result);
            }
        }).catch(() => { });
    }

    const handleSearchRidResultClick = (sel) => {

        let sv = {};
        let mr = { ...modifiedRid };

        // faccio merge dei dati (se non sono array, se sono array riempio le select)
        for (let key in mr) {
            if (sel.hasOwnProperty(key)) {
                if (!Array.isArray(sel[key])) {
                    mr[key] = sel[key];
                }
                else {
                    sv[key] = sel[key];
                }
            }
        }

        setModifiedRid(mr);
        setSelectValues(sv);

        setSearchRidOpen(false);
    }

    useEffect(() => {
        if (rid) {

            let sv = {};
            // recupero codice onu se selezionato per riempire select
            if (rid.verify && Array.isArray(rid.verify.ExtraDataFromRegistry)) {

                let selectedOnu = rid.verify.ExtraDataFromRegistry.find(item => item.SELECTED === true || (item.UNPrg === rid.UNPrg && item.UNNumber === rid.UNNumber));

                for (let key in selectedOnu) {
                    if (rid.modified.hasOwnProperty(key)) {
                        if (Array.isArray(selectedOnu[key])) {
                            sv[key] = selectedOnu[key];
                        }
                    }
                }
            }
            setSelectValues(sv);
        }
    }, [rid]);

    useEffect(() => {

        if (open === true) {
            setRid(JSON.parse(JSON.stringify(props.rid)));
            let ridData = {};
            for (const [key, value] of Object.entries(props.rid.modified)) {
                //Recupero prima il "modificato", poi "orfeus" ed infine "extracted"
                if (value) {
                    ridData[key] = value;
                }
                else if (props.rid.orfeus[key]) {
                    ridData[key] = props.rid.orfeus[key];
                }
                else {
                    ridData[key] = props.rid.extracted[key]
                }
            }
            setModifiedRid(ridData);
        }
    }, [open])

    //manca descrizione
    const datiGenerali = ["UNPrg", "UNNumber", "AdditionalInformation", "LawYear",
        "DangerLabel","DangerLabel2","DangerLabel3","DangerLabel4","DangerLabel5", "FasciaRid",
        "Class", "ClassificationCode", "EmptyPackingCode", "NumberOfPackages", "MassSinglePackage",
        "WeightExplosiveMass", "HazardNumber", "MinValveOpenDate", "PackingGroup", "Weight",
        "TelefonoDitta", "FumigationDate", "DisposizioniSpeciali", "ResiduoInImballaggio"];

    const flagAltriDati = ["FlagRifiuto", "FlagRifiuto2_1_3_5_5", //"*vuoto RID",
        "FlagGIRScaduto", "FlagMarittimoAereo", "FlagAltaTemperatura",
        "FlagStradaRotaia", "FlagMaterieAutoreattive4_1", "FlagPuliziaRiparazione",
        "FlagTrasporto2_2_52_1_9", "FlagTrasporto2_2_52_1_8", "FlagEtichettaNonObbligatoria",
        "FlagTrasporto2_1_41_1_15", "FlagTrasporto2_2_41_1_13", "FlagTabellaArancio","FlagBK",
        "FlagPerossidiTipoGNon5_2", "MercePericolosaPerAmbiente","MercePericolosaAltoRischio",
        "FlagCisternaScaduta", "FlagTrasporto2_1_2_8", "FlagTrasportoDS363"];

    const altriDati = ["ResponsabileDitta", "Disposizione640", "TechnicalDescription","LongTextDescription",
        "AutoritaRiconoscimentoClassificazione", "AutoritaImballaggiP101", "SuccessiviCodiciONU",
        "AgentiFumiganti", "EsenzioneClasse", "TipoDeroghe",
        "ClsPcoPir", "SolidoAlloStatoFuso","RecipientiUS",
        "PaeseBK","QuantitaStimataRifiuto","StabilizzazioneChimica",
        "ParagrafoGIRScaduto", "ParagrafoPuliziaRiparazione"];

    return (
        <>
            <Button variant='text' size='small' startIcon={<OpenInNew />} onClick={handleOpen} />
            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>Convalida RID</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'red'
                    })}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="Dati Generali" />
                        <Tab label="Altri Dati" />
                    </Tabs>

                    {tabValue === 0 && (
                        <Box mt={2} display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
                            {
                                rid &&
                                datiGenerali.map(dg => {
                                    if (dg === 'UNNumber') {
                                        return <TextField label={t("distinta.rid." + dg)} value={modifiedRid[dg]} onChange={(e) => handleChange(dg, e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => searchRIDByOnu(modifiedRid[dg])} edge="end">
                                                            <Search />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}

                                            size="small" />
                                    }

                                    else if (dg === 'EmptyPackingCode' || dg === 'ResiduoInImballaggio') {
                                        return <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                            <InputLabel id={dg}>{t("distinta.rid." + dg)}</InputLabel>
                                            <Select value={modifiedRid[dg]} onChange={(e) => handleChange(dg, e.target.value)} size='small' label={dg} fullWidth>
                                                {selectValues[dg] &&
                                                    selectValues[dg].map(val =>
                                                        <MenuItem value={val.code}>{val.descr}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    }

                                    else if (dg === 'MinValveOpenDate' || dg === 'FumigationDate'){
                                        return <LocalizationProvider adapterLocale='it' dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    className='datepickerRid'
                                                    label={t("distinta.rid." + dg)}
                                                    value={modifiedRid[dg] ? dayjs(modifiedRid[dg]) : null}
                                                    onChange={(val) => handleChange(dg, val)}
                                                />
                                            </LocalizationProvider>
                                    }

                                    else {
                                        return <TextField label={t("distinta.rid." + dg)} value={modifiedRid[dg]} onChange={(e) => handleChange(dg, e.target.value)} size="small" />
                                    }
                                })
                            }
                        </Box>
                    )}

                    {tabValue === 1 && (
                        <Box mt={2}>
                            <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
                                {rid &&
                                    flagAltriDati.map(fad => {
                                        return <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
                                            <InputLabel id={fad}>{t("distinta.rid." + fad)}</InputLabel>
                                            <Select value={modifiedRid[fad]} onChange={(e) => handleChange(fad, e.target.value)} size='small' label={fad} fullWidth>
                                                <MenuItem value="S">SI</MenuItem>
                                                <MenuItem value="N">NO</MenuItem>
                                            </Select></FormControl>
                                    })
                                }
                            </Box>

                            <Box mt={4} display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>

                                {rid &&
                                    altriDati.map(ad => {

                                        if (ad === 'PaeseBK' || ad === 'ParagrafoGIRScaduto' || ad === 'ParagrafoPuliziaRiparazione') {
                                            return <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                                <InputLabel id={ad}>{t("distinta.rid." + ad)}</InputLabel>
                                                <Select value={modifiedRid[ad]} onChange={(e) => handleChange(ad, e.target.value)} size='small' label={ad} fullWidth>
                                                    {selectValues[ad] &&
                                                        selectValues[ad].map(val =>
                                                            <MenuItem value={val.code}>{val.descr}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        }

                                        else
                                            return <TextField label={t("distinta.rid." + ad)} onChange={(e) => handleChange(ad, e.target.value)} value={modifiedRid[ad]} size="small" />
                                    })
                                }
                            </Box>

                        </Box>
                    )}


                    <Box sx={{ float: "left" }} mt={2}>
                        <Button variant="contained" color="error" onClick={() => { updateRid(false, true); handleClose(); }}>Scarta</Button>
                        <Button sx={{ ml: 1 }} variant="contained" color="success" onClick={() => { updateRid(true, false); handleClose(); }}>Convalida</Button>
                    </Box>
                    <Box sx={{ float: "right" }} mt={2}>
                        <Button sx={{ ml: 1 }} variant="contained" color="primary" onClick={() => { updateRid(false, false); handleClose(); }}>Modifica</Button>
                    </Box>

                </DialogContent>
            </Dialog>

            <Dialog open={searchRidOpen} onClose={() => setSearchRidOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Ricerca Codice ONU</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setSearchRidOpen(false)}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'red'
                    })}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ fontWeight: "bold" }}>
                                    <TableCell>Progressivo</TableCell>
                                    <TableCell>Codice ONU</TableCell>
                                    <TableCell>Descrizione</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchRidResults.map((result) => (
                                    <TableRow
                                        key={result.id}
                                        onClick={() => handleSearchRidResultClick(result)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>{result.UNPrg}</TableCell>
                                        <TableCell>{result.UNNumber}</TableCell>
                                        <TableCell>{result.descrizione_codice_onu_in_inglese}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default RIDDialog;